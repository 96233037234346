:root {
  /* 克莱因蓝 */
  --color-klein-blue: rgb(0,47,167);
  --color-hooks-green: rgb(74,122,110);
  --color-geek-black: rgb(0, 0, 0);
  /* --bg-color-1: var(--color-klein-blue); */
  --bg-color-1: var(--color-hooks-green);
  /* --bg-color-1: var(--color-geek-black); */
}

@font-face {
  font-family: Share Tech Mono;
  src: url('./assets/ShareTechMono-Regular.ttf');
}

.app {
  min-height: 100vh;
}
.layer-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.layer-features {
  min-height: 100vh;
  /* background-color: #ddd; */
}
.layer-features > .grid-stack > .grid-stack-item > .grid-stack-item-content {
  inset: 0;
}
.layer-features .editing-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: move;
  left: 0;
  top: 0;
}
